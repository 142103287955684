import React, { useEffect, useState } from "react";
import {
  createProgram,
  getAllProgram,
  getProgramById,
  updateProgram,
} from "../../../../api/program";
import { useNavigate, useParams } from "react-router";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { border } from "@chakra-ui/react";

const AddNewProgram = ({ isEditable }) => {
  const dispatch = useDispatch();
  const [inputList, setinputList] = useState({
    date: "",
    sessions: [
      {
        session_name: "",
        session_timing: "",
        speeches: [
          {
            speaker: "",
            speech_name: "",
          },
        ],
      },
    ],
  });
  const [date, setdate] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);
  const navigate = useNavigate();

  const params = useParams();

  function formatDate(inputDate) {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  }

  function formatDateReverse(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); 
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const Submit = async (e) => {
    e.preventDefault();
    if (!isEditable) {
      try {
        const data = await createProgram(inputList);
        toast.success(data.message);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    } else {
      try {
        const data = await updateProgram(inputList, params.id);
        toast.success(data.message);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isEditable) {
      const id = params.id;
      fetchProgramById(id);
      async function fetchProgramById(id) {
        try {
          const data = await getProgramById(id);
          setinputList(data.data);
          let newdate = formatDateReverse(data.data.date);
          setdate(newdate);
        } catch (error) {
          if (error.message === "Please ReLogin") {
            toast.error(error.message);
            localStorage.removeItem("userType");
            localStorage.removeItem("token");
            dispatch({ type: "logout" });
          }
          toast.error(error.message);
        }
      }
    }
  }, [isEditable, params.id]);

  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    const list = { ...inputList };
    list.sessions[index][name] = value;
    setinputList(list);
  };

  const handlespeechinputchange = (e, i, index) => {
    const { name, value } = e.target;
    const list = { ...inputList };
    list.sessions[i].speeches[index][name] = value;
    setinputList(list);
  };

  const handleremove = (e, index) => {
    e.preventDefault();
    const list = { ...inputList };
    list.sessions.splice(index, 1);
    setinputList(list);
  };

  const handleaddclick = (e) => {
    e.preventDefault();
    setinputList({
      ...inputList,
      sessions: [
        ...inputList.sessions,
        { session_name: "", session_timing: "", speeches: [] },
      ],
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setdate(value);
    let newdate = formatDate(value);
    // setdate(newdate);
    setinputList({ ...inputList, [name]: newdate });
  };

  const handleSpeechAdd = (e, i) => {
    e.preventDefault();

    const chosenSessionIndex = i;
    const updatedSessions = inputList.sessions.map((session, index) => {
      if (index === chosenSessionIndex) {
        return {
          ...session,
          speeches: [
            ...session.speeches,
            {
              speaker: "",
              speech_name: "",
            },
          ],
        };
      } else {
        return session;
      }
    });

    setinputList({
      ...inputList,
      sessions: updatedSessions,
    });
  };
  const handleSpeechRemove = (e, i, index) => {
    e.preventDefault();
    const chosenSessionIndex = i;
    const chosenSpeechIndex = index;
    const updatedSessions = inputList.sessions.map((session, sessionIndex) => {
      if (sessionIndex === chosenSessionIndex) {
        return {
          ...session,
          speeches: session.speeches.filter(
            (speech, speechIndex) => speechIndex !== chosenSpeechIndex
          ),
        };
      } else {
        return session;
      }
    });

    setinputList({
      ...inputList,
      sessions: updatedSessions,
    });
  };

  return (
    <div className={`yourComponent ${isExpanded ? "expanded" : ""}`}>
      <div className="horizontalLine"></div>
      <div className="header">
        <div className="title">Program Details</div>
        <button className="expandButton" onClick={toggleExpand}>
          {isExpanded ? "-" : "+"}
        </button>
      </div>
      {isExpanded && (
        <form className="form-container" onSubmit={Submit} id="program">
          <div className="form-row">
            <div className="form-col">
              <label>Program Date :</label>
              <input
                type="date"
                name="date"
                id="date"
                value={date}
                onChange={handleOnChange}
              />
            </div>
          </div>

          {inputList.sessions.map((x, i) => {
            return (
              <div
                className="form-container"
                style={{ backgroundColor: "#ebebeb" }}
              >
                <div className="form-row">
                  <div class="form-group form-col">
                    <label>Session Name : </label>
                    <input
                      type="text"
                      name="session_name"
                      class="form-control"
                      onChange={(e) => handleinputchange(e, i)}
                      value={inputList.sessions[i].session_name}
                    />
                  </div>
                  <div class="form-group form-col">
                    <label>Session Timings</label>
                    <input
                      type="text"
                      name="session_timing"
                      class="form-control"
                      onChange={(e) => handleinputchange(e, i)}
                      value={inputList.sessions[i].session_timing}
                    />
                  </div>
                  <div class="form-group form-col">
                    {inputList.sessions.length !== 1 && (
                      <button
                        className="btn"
                        onClick={(e) => handleremove(e, i)}
                      >
                        Remove
                      </button>
                    )}
                    {
                      <button
                        onClick={(e) => handleSpeechAdd(e, i)}
                        className="btn"
                        style={{ width: "149px" }}
                      >
                        Add Speech
                      </button>
                    }
                  </div>
                </div>

                {inputList.sessions[i].speeches.map((x, index) => {
                  return (
                    <div className="form-row" style={{ marginLeft: "45px" }}>
                      <div class="form-group form-col">
                        <label>Speech Name : </label>
                        <input
                          type="text"
                          name="speech_name"
                          class="form-control"
                          onChange={(e) => handlespeechinputchange(e, i, index)}
                          value={
                            inputList.sessions[i].speeches[index].speech_name
                          }
                        />
                      </div>
                      <div class="form-group form-col">
                        <label>Speaker Name : </label>
                        <input
                          type="text"
                          name="speaker"
                          class="form-control"
                          onChange={(e) => handlespeechinputchange(e, i, index)}
                          value={inputList.sessions[i].speeches[index].speaker}
                        />
                      </div>
                      {inputList.sessions[i].speeches.length !== 0 && (
                        <button
                          className="btn"
                          onClick={(e) => handleSpeechRemove(e, i, index)}
                          style={{ fontSize: "1.2rem" }}
                        >
                          -
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}

          <div
            className="form-row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "117vw",
            }}
          >
            <div className="form-col">
              <button type="submit" className="btn">
                {isEditable ? "Save" : "Submit"}
              </button>
            </div>
            <div className="form-col">
              <button onClick={(e) => handleaddclick(e)} className="btn">
                Add Session
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddNewProgram;
