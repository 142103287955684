import React, { useEffect, useState } from "react";
import "./styles.css";
import * as XLSX from "xlsx";
import { useTable, usePagination } from "react-table";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { getAllCompany } from "../../../../api/companyMaster";
import { useDispatch } from "react-redux";

const ListOfCompanies = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  // const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { Header: "ID", accessor: "id" },
    { Header: "Company Name", accessor: "companyName" },
    { Header: "Company Code", accessor: "companyCode" },
    { Header: "Address1", accessor: "address1" },
    { Header: "Address2", accessor: "address2" },
    { Header: "Pincode", accessor: "pinCode" },
    { Header: "ISD Code", accessor: "isdCode" },
    { Header: "Company Name On Batch", accessor: "companyNameOnBatch" },
    {
      Header: "Action",
      accessor: "actions",
      Cell: ({ row }) => (
        <button
          onClick={() => {
            handleAction(row.original.id);
          }}
          className="bg-blue-500 text-white px-2 py-1 rounded"
        >
          Edit
        </button>
      ),
    },
  ]);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    fetchAllCompanies();
    async function fetchAllCompanies() {
      try {
        const data = await getAllCompany();
        let arr = [];
        for (let i = 0; i < data.data.length; i++) {
          const element = data.data[i];

          const newObject = {
            id: element?._id,
            companyName: element?.companyName,
            companyCode: element?.companyCode,
            address1: element?.address1,
            address2: element?.address2,
            pinCode: element?.pinCode,
            isdCode: element?.isdCode,
            companyNameOnBatch: element?.companyNameOnBatch,
          };
          arr.push(newObject);
        }
        setData(arr);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
  }, [navigate]);

  const handleAction = (id) => {
    navigate(`/companymaster/editcompany/${id}`);
  };
  const exportToExcel = () => {
    const wsData = data.map((item) => ({
      ID: item.id,
      "Location Id": item.locationId,
      "Location Name": item.locationName,
      Parent: item.parent,
      "Location Type": item.locationType,
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "file");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "file.xlsx";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination
  );

  return (
    <>
      <div className="yourComponent expanded">
        <div className="horizontalLine"></div>
        <div className="header">
          <div className="title">Search</div>
        </div>
      </div>
      <div className={`yourComponent ${isExpanded ? "expanded" : ""}`}>
        <div className="horizontalLine"></div>
        <div className="header">
          <div className="title">Search Output</div>
          <button className="expandButton" onClick={toggleExpand}>
            {isExpanded ? "-" : "+"}
          </button>
        </div>
        {isExpanded && (
          <div className="container mx-auto mt-5">
            <div
              className="form-row"
              style={{
                backgroundColor: "rgb(229 231 235)",
                padding: "5px",
                height: " 36px",
              }}
            >
              <button
                className="btn"
                onClick={exportToExcel}
                style={{ backgroundColor: "#fff", borderRadius: "8px" }}
              >
                Export To Excel
              </button>
            </div>
            <div className="overflow-x-auto">
              <table {...getTableProps()} className="table-auto w-full">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="bg-gray-200"
                      style={{ fontSize: "14px" }}
                    >
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()} className="py-2 px-4">
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="border px-4 py-2"
                              style={{ fontSize: "12px" }}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="mt-4 flex flex-wrap justify-between">
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                className="responsive-button"
              >
                {"<<"}
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="responsive-button"
              >
                {"<"}
              </button>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="responsive-button"
              >
                {">"}
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className="responsive-button"
              >
                {">>"}
              </button>
              <span className="mt-1">
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="p-2 border rounded responsive-select"
              >
                {[5, 10, 20].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ListOfCompanies;
