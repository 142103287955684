import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { authUser } from "../api/auth/authUser";
import { toast, ToastContainer } from "react-toastify";
import pic from "../assets/app_logo.png";
import { useDispatch } from "react-redux";
const Login = () => {
  const dispath = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispath({ type: "login" });
      navigate("/");
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(formData.email)
    ) {
      alert("Invalid email address format. Please enter a valid email.");
      return;
    }
    try {
      setLoading(true);
      const data = await authUser(formData);
      setLoading(false);
      localStorage.setItem("userType", data.data.userType);
      localStorage.setItem("token", data.data.Token);
      toast.success(data.message);
      dispath({ type: "login" });
      navigate("/");
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
      return;
    }
  };
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <header
        className="bg-[#3c8dbc]"
        style={{
          textAlign: "center",
          color: "white",
          padding: "0.5rem",
          fontSize: "2rem",
        }}
      >
        Seminar Management System
      </header>
      <div className="w-full h-auto mt-10 flex flex-wrap justify-center items-center">
        <div className="flex justify-center items-center md:w-1/2 w-full">
          <img src={pic} alt="" className="w-[50%] h-[50%]" />
        </div>
        <div className="md:w-1/2 w-full mx-auto p-4">
          <div className="flex flex-col gap-4">
            <h2 className="text-center text-2xl ">Login</h2>
            <form onSubmit={onSubmit}>
              <div className="flex flex-col gap-2 mb-4">
                <label htmlFor="email">Email:</label>
                <input
                  required
                  className="px-3 py-1 text-sm w-full"
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col gap-2 mb-4">
                <label htmlFor="password">Password:</label>
                <input
                  required
                  className="px-3 py-1 text-sm w-full"
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
              <button
                disabled={loading}
                className=" text-white md:w-[50%] bg-[#4485aa] px-7 py-2 rounded-full hover:bg-[#3c7da2]"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        height="50px"
        width="200px"
      />
    </div>
  );
};

export default Login;
