import axios from "axios";
// const token = localStorage.getItem("token");
const baseURL = process.env.REACT_APP_AWS_URL;
export const createProgram = async (formData) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(`${baseURL}/api/v1/createProgram`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const getProgramById = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(`${baseURL}/api/v1/Program/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const getAllProgram = async () => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(
      `${baseURL}/api/v1/program/get/All`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const updateProgram = async (inputList, id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.put(
      `${baseURL}/api/v1/program/${id}`,
      inputList,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};
