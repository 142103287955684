import axios from "axios";
const baseURL = process.env.REACT_APP_AWS_URL;
export const getAllCountries = async () => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(
      `${baseURL}/api/v1/getAllLocationCountry`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const getAllStates = async () => {
  // console.log(token);
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(`${baseURL}/api/v1/getAll/LocationState`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};
export const getAllCity = async () => {
  // console.log(token);
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(`${baseURL}/api/v1/getAllLocationCity`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const createLocation = async (formData) => {
  try {
    const token = localStorage.getItem("token");
    const { data } = await axios.post(`${baseURL}/api/v1/location`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const getAllStatesbyCountryId = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const { data } = await axios.get(
      `${baseURL}/api/v1/getAllLocationState/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const getAllStatesbyCityId = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const { data } = await axios.get(
      `${baseURL}/api/v1/getAllLocationCityByState/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const getAllCitiesByCountryId = async (id) => {
  try {
    const data = await axios.get(
      `${baseURL}/api/v1/getAllLocationCityByCountry/${id}`
    );
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const getLocationById = async (id) => {
  try {
    const data = await axios.get(`${baseURL}/api/v1/location/${id}`);
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const updateLocation = async (formData, id) => {
  try {
    const token = localStorage.getItem("token");
    const { data } = await axios.put(
      `${baseURL}/api/v1/locations/${id}`,
      formData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};
