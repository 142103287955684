import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useTable, usePagination } from "react-table";
import { useNavigate } from "react-router-dom";
import { getAllSponsors } from "../../../../api/sponsor";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
const ListOfSponsors = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { Header: "#", accessor: "id" },
    { Header: "Event", accessor: "event" },
    { Header: "Sponsor Name", accessor: "sponsorName" },
    { Header: "Sponsor Type", accessor: "sponsorType" },
    { Header: "Short Name", accessor: "shortName" },
    { Header: "Pin Code", accessor: "pinCode" },
    { Header: "Sponsor From Date", accessor: "sponsorFromDate" },
    { Header: "Sponser To date", accessor: "sponserToDate" },
    { Header: "Sponsor Web Url", accessor: "sponserWebUrl" },
    { Header: "Contact Person", accessor: "contactPerson" },
    { Header: "Contact Person No", accessor: "contactPersonNo" },
    { Header: "Is Published", accessor: "isPublished" },
    { Header: "Show In Order", accessor: "showInOrder" },
    {
      Header: "Action",
      accessor: "actions",
      Cell: ({ row }) => (
        <button
          onClick={() => handleAction(row.original.id)}
          className="bg-blue-500 text-white px-2 py-1 rounded"
        >
          Edit
        </button>
      ),
    },
  ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAction = (id) => {
    navigate(`/sponsors/editsponsor/${id}`);
  };

  const exportToExcel = () => {
    const wsData = data.map((item) => ({
      ID: item.id,
      Event: item.event,
      "Sponsor Name": item.sponsorName,
      "Sponsor Type": item.sponsorType,
      "Short Name": item.shortName,
      "Pin Code": item.pinCode,
      "Sponsor From Date": item.sponsorFromDate,
      "Sponser To date": item.sponserToDate,
      "Sponsor Web Url": item.sponserWebUrl,
      "Contact Person": item.contactPerson,
      "Contact Person No": item.contactPersonNo,
      "Is Published": item.isPublished,
      "Show In Order": item.showInOrder,
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "file");

    // Generate the Excel file as an ArrayBuffer
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert the ArrayBuffer to a Blob
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a temporary URL to the blob
    const url = window.URL.createObjectURL(blob);

    // Create a link to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "file.xlsx";
    a.click();

    // Release the temporary URL and clean up
    window.URL.revokeObjectURL(url);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination
  );

  useEffect(() => {
    fetchListOfSponsors();
    async function fetchListOfSponsors() {
      try {
        const data = await getAllSponsors();

        let arr = [];
        for (let i = 0; i < data.data.length; i++) {
          const element = data.data[i];

          const newObject = {
            id: element?._id,
            event: element?.eventId?.eventName,
            sponsorName: element?.sponserName,
            sponsorType: element?.sponserType,
            shortName: element?.sponserShortname,
            pinCode: element?.pinCode,
            sponsorFromDate: element?.sponserFromDate,
            sponserToDate: element?.sponserToDate,
            sponserWebUrl: element?.sponserWebUrl,
            contactPerson: element?.contactPerson,
            contactPersonNo: element?.contactPersonNo,
            isPublished: element?.isPublished ? "True" : "False",
            showInOrder: element?.showInOrder,
          };
          arr.push(newObject);
        }
        setData(arr);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
  }, []);
  return (
    <>
      <div className={`yourComponent ${isExpanded ? "expanded" : ""}`}>
        <div className="horizontalLine"></div>
        <div className="header">
          <div className="title">Search Result</div>
          <button className="expandButton" onClick={toggleExpand}>
            {isExpanded ? "-" : "+"}
          </button>
        </div>
        {isExpanded && (
          <div className="container mx-auto mt-5">
            <div
              className="form-row"
              style={{
                backgroundColor: "rgb(229 231 235)",
                padding: "5px",
                height: " 36px",
              }}
            >
              <button
                className="btn"
                onClick={exportToExcel}
                style={{ backgroundColor: "#fff", borderRadius: "8px" }}
              >
                Export To Excel
              </button>
            </div>
            <div className="overflow-x-auto">
              <table {...getTableProps()} className="table-auto w-full">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="bg-gray-200"
                      style={{ fontSize: "14px" }}
                    >
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()} className="py-2 px-4">
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="border px-4 py-2"
                              style={{ fontSize: "12px" }}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="mt-4 flex flex-wrap justify-between">
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                className="responsive-button"
              >
                {"<<"}
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="responsive-button"
              >
                {"<"}
              </button>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="responsive-button"
              >
                {">"}
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className="responsive-button"
              >
                {">>"}
              </button>
              <span className="mt-1">
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="p-2 border rounded responsive-select"
              >
                {[5, 10, 20].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ListOfSponsors;
