import axios from "axios";
// const token = localStorage.getItem("token");
const baseURL = process.env.REACT_APP_AWS_URL;

export const createExhibitor = async (formData) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(`${baseURL}/api/v1/Exhibitor`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const getExhibitorById = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(`${baseURL}/api/v1/Exhibitor/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const updateExhibitor = async (formData, id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.put(
      `${baseURL}/api/v1/Exhibitor/${id}`,
      formData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const getAllExhibitors = async () => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(`${baseURL}/api/v1/Exhibitor`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};
