import axios from "axios";
// const token = localStorage.getItem("token");
const baseURL = process.env.REACT_APP_AWS_URL;
export const getEvents = async () => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(`${baseURL}/api/v1/Event`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};
