import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getAllProgram } from "../../../../api/program";
import "./index.css";

const ListOfPrograms = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [programData, setprogramData] = useState([]);
  const [sessions, setsessions] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

    const handleAction = (id) => {
      navigate(`/programs/editprogram/${id}`);
    };

  useEffect(() => {
    fetchListOfPrograms();
    async function fetchListOfPrograms() {
      try {
        const data = await getAllProgram();
        setprogramData(data.data);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
  }, []);


  return (
    <div className="parent">
      {programData.map((program) => (
        <div className="program">
          <div className="btnparent">
            <button
              onClick={() => handleAction(program._id)}
              className="bg-blue-500 text-white px-2 py-1 rounded edit-btn"
            >
              Edit
            </button>
          </div>
          <div className="date">{program.date}</div>
          {program.sessions.map((session) => (
            <div>
              <div className="session">
                <div className="p2">{session.session_timing + " : "}</div>
                <div className="p2">{session.session_name}</div>
              </div>
              <div>
                {session.speeches.map((speech) => (
                  <div className="speech">
                    <div>{speech.speech_name}</div>
                    <div>{speech.speaker}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ListOfPrograms;
