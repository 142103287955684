import React, { useState } from "react";
import styles from "./styles.module.css";
import { createPaper } from "../../../../api/paper";

const AddNewPaper = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [formData, setFormData] = useState({
    eventId: "6507eb6a9ca10beebc562986",
    abstractPaperTitle: "",
    author: "",
    abstractPaperDescription: "",
    abstractPaperUrl: "",
    isPublished: false,
  });

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckBox = () => {
    setFormData((prevState) => {
      return {
        ...prevState,
        isPublished: !prevState.isPublished,
      };
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = await createPaper(formData);
      setLoading(false);
      alert(data.message);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  };

  return (
    <div className={`yourComponent ${isExpanded ? "expanded" : ""}`}>
      <div className="horizontalLine"></div>
      <div className="header">
        <div className="title">Paper Details</div>
        <button className="expandButton" onClick={toggleExpand}>
          {isExpanded ? "-" : "+"}
        </button>
      </div>
      {isExpanded && (
        <form className="form-container" onSubmit={submit}>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Abstract Paper Title :</label>
              <input
                type="text"
                required
                name="abstractPaperTitle"
                id="abstractPaperTitle"
                onChange={handleOnChange}
                value={formData.abstractPaperTitle}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Author :</label>
              <input
                type="text"
                name="author"
                id="author"
                value={formData.author}
                onChange={handleOnChange}
              />
            </div>
            <div className="form-col">
              <label className="mandatory-label">
                Abstract paper Description :
              </label>
              <textarea
                id="abstractPaperDescription"
                name="abstractPaperDescription"
                rows="4"
                cols="50"
                value={formData.abstractPaperDescription}
                onChange={handleOnChange}
              ></textarea>
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Event Paper Url :</label>
              <input
                type="text"
                name="abstractPaperUrl"
                id="abstractPaperUrl"
                value={formData.abstractPaperUrl}
                onChange={handleOnChange}
              />
            </div>
            <div className="form-col checkbox-style">
              <label htmlFor="checkbox">Is Published:</label>
              <input
                type="checkbox"
                id="isPublished"
                name="isPublished"
                onChange={handleCheckBox}
                value={formData.isPublished}
                style={{ height: "14px" }}
              />
            </div>
          </div>
          <div className="form-row">
            <div className={`form-col ${loading ? " opacity-70" : ""}`}>
              <button className="btn" disabled={loading}>
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddNewPaper;
