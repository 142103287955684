import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import "./styles.css";
import { useTable, usePagination } from "react-table";
import { baseUrl } from "../../../../Constants/network";
import axios from "axios";
import Popup1 from "../../../../Components/Popup/Popup1";
import {
  getAllStates,
  getAllCity,
  getAllCountries,
} from "../../../../api/locationMaster";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const LocationList = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [locationType, setLocationType] = useState("");
  const [data, setData] = useState([]);
  const [columns, setCloumns] = useState([
    { Header: "ID", accessor: "id" },
    { Header: "Location Name", accessor: "locationName" },
    {
      Header: "Action",
      accessor: "actions",
      Cell: ({ row }) => (
        <button
          onClick={() => handleAction(row.original.id)}
          className="bg-blue-500 text-white px-2 py-1 rounded"
        >
          Edit
        </button>
      ),
    },
  ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleAction = (id) => {
    navigate(`/locationmaster/editlocation/${id}`);
  };

  const exportToExcel = () => {
    const wsData = data.map((item) => ({
      ID: item.id,
      "Location Id": item.locationId,
      "Location Name": item.locationName,
      Parent: item.parent,
      "Location Type": item.locationType,
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "file");

    // Generate the Excel file as an ArrayBuffer
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert the ArrayBuffer to a Blob
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a temporary URL to the blob
    const url = window.URL.createObjectURL(blob);

    // Create a link to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "file.xlsx";
    a.click();

    // Release the temporary URL and clean up
    window.URL.revokeObjectURL(url);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination
  );

  const handleGetLocation = (e) => {
    e.preventDefault();
    getLocationByLocationType(locationType);
  };

  const getLocationByLocationType = async (type) => {
    let arr = [];
    let arr2 = [];
    if (type === "Country") {
      try {
        const data = await getAllCountries();
        arr = data.data;
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    } else if (type === "State") {
      try {
        const data = await getAllStates();
        arr = data.data;
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    } else {
      try {
        const data = await getAllCity();
        arr = data.data;
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      const newObject = {
        id: element?._id,
        locationName: element?.locationName,
      };
      arr2.push(newObject);
    }
    setData(arr2);
  };

  return (
    <>
      <div className="yourComponent expanded">
        <div className="horizontalLine"></div>
        <div className="header">
          <div className="title">Search</div>
        </div>
        <form className="form-container" onSubmit={handleGetLocation}>
          <div className="form-row">
            <div className="form-col">
              <label htmlFor="currency">Location Type : </label>
              <select
                type="text"
                id="locationType"
                name="locationType"
                value={locationType}
                onChange={(e) => {
                  setLocationType(e.target.value);
                }}
              >
                <option value="">--select--</option>
                <option value="Country">Country</option>
                <option value="State">State</option>
                <option value="City">City</option>
              </select>
            </div>

            <div className="form-col">
              <button className="btn">View</button>
            </div>
          </div>
        </form>
      </div>
      <div className={`yourComponent ${isExpanded ? "expanded" : ""}`}>
        <div className="horizontalLine"></div>
        <div className="header">
          <div className="title">List of country</div>
          <button className="expandButton" onClick={toggleExpand}>
            {isExpanded ? "-" : "+"}
          </button>
        </div>
        {isExpanded && (
          <div className="container mx-auto mt-5">
            <div
              className="form-row"
              style={{
                backgroundColor: "rgb(229 231 235)",
                padding: "5px",
                height: " 36px",
              }}
            >
              <button
                className="btn"
                onClick={exportToExcel}
                style={{ backgroundColor: "#fff", borderRadius: "8px" }}
              >
                Export To Excel
              </button>
            </div>
            <div className="overflow-x-auto">
              <table {...getTableProps()} className="table-auto w-full">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="bg-gray-200"
                      style={{ fontSize: "14px" }}
                    >
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()} className="py-2 px-4">
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="border px-4 py-2"
                              style={{ fontSize: "12px" }}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="mt-4 flex flex-wrap justify-between">
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                className="responsive-button"
              >
                {"<<"}
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="responsive-button"
              >
                {"<"}
              </button>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="responsive-button"
              >
                {">"}
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className="responsive-button"
              >
                {">>"}
              </button>
              <span className="mt-1">
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="p-2 border rounded responsive-select"
              >
                {[5, 10, 20].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LocationList;
