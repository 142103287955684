import React, { useEffect, useState } from "react";
import { getCompanyCategory } from "../../../../api/companyCategory";
import {
  getAllCitiesByCountryId,
  getAllCountries,
} from "../../../../api/locationMaster";
import {
  addCompany,
  getCompanyById,
  updateCompany,
} from "../../../../api/companyMaster";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";

const AddCompany = ({ isEditable }) => {
  // const countryNames = getNames();
  const [isExpanded, setIsExpanded] = useState(true);
  const [companyCategories, setCompanyCategories] = useState([]);
  const [Countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [countryName, setCountryName] = useState("");
  // const [cityName, setCityName] = useState("");

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // adding company

  const [companydata, setCompanydata] = useState({
    companyCategoryId: "",
    companyName: "",
    companyCode: "",
    address1: "",
    address2: "",
    countryId: "",
    stateCityId: "",
    pinCode: "",
    companyNameOnBatch: "",
    isdCode: "",
  });
  const params = useParams();
  const dispatch = useDispatch();

  const changedata1 = (e) => {
    const { name, value } = e.target;
    setCompanydata({
      ...companydata,
      [name]: value,
    });
  };
  const fetchAllCitiesByCountryId = async (id) => {
    try {
      const data = await getAllCitiesByCountryId(id);
      setCities(data.data.data);
    } catch (error) {
      if (error.message === "Please ReLogin") {
        toast.error(error.message);
        localStorage.removeItem("userType");
        localStorage.removeItem("token");
        dispatch({ type: "logout" });
      }
      toast.error(error.message);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!isEditable) {
      try {
        const data = await addCompany(companydata);
        toast.success(data.message);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          //redirect to login page
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    } else {
      try {
        const data = await updateCompany(companydata, params.id);

        toast.success(data.message);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          //redirect to login page
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    fetchCompanyCategories();
    fetchAllCountries();
    setCompanydata({
      companyCategoryId: "",
      companyName: "",
      companyCode: "",
      address1: "",
      address2: "",
      countryId: "",
      stateCityId: "",
      pinCode: "",
      companyNameOnBatch: "",
      isdCode: "",
    });
    setCountryName("");
    async function fetchAllCountries() {
      try {
        const data = await getAllCountries();
        setCountries(data.data);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          //redirect to login page
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
          return;
        }
        toast.error(error.message);
      }
    }
    async function fetchCompanyCategories() {
      //api call
      try {
        const data = await getCompanyCategory();
        setCompanyCategories(data.data);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          //redirect to login page
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
          return;
        }
        toast.error(error.message);
      }
    }
  }, [isEditable]);

  useEffect(() => {
    const id = params.id;
    if (isEditable) {
      fetchCompanybyId(id);
      async function fetchCompanybyId(id) {
        try {
          const data = await getCompanyById(id);

          setCompanydata({
            companyCategoryId: data.data?.companyCategoryId._id,
            companyName: data.data?.companyName,
            companyCode: data.data?.companyCode,
            address1: data.data?.address1,
            address2: data.data?.address2,
            countryId: data.data?.countryId._id,
            stateCityId: data.data?.stateCityId._id,
            pinCode: data.data?.pinCode,
            companyNameOnBatch: data.data?.companyNameOnBatch,
            isdCode: data.data?.isdCode,
          });
          setCountryName(data.data?.countryId.locationName);
          // setCityName(data.data?.stateCityId.locationName);
          fetchAllCitiesByCountryId(data.data.countryId._id);
        } catch (error) {
          if (error.message === "Please ReLogin") {
            //redirect to login page
            toast.error(error.message);
            localStorage.removeItem("userType");
            localStorage.removeItem("token");
            dispatch({ type: "logout" });
          }
          toast.error(error.message);
        }
      }
    }
  }, [isEditable, params.id]);

  const handleCountryChange = (e) => {
    const country = Countries.find((item) => {
      return item.locationName === e.target.value;
    });
    console.log(country._id);
    setCountryName(country.locationName);
    setCompanydata({ ...companydata, countryId: country._id });
    fetchAllCitiesByCountryId(country._id);
  };

  return (
    <div className={`yourComponent ${isExpanded ? "expanded" : ""}`}>
      <div className="horizontalLine"></div>
      <div className="header">
        <div className="title">Company Details</div>
        <button className="expandButton" onClick={toggleExpand}>
          {isExpanded ? "-" : "+"}
        </button>
      </div>
      {isExpanded && (
        <form className="form-container" id="Company" onSubmit={submit}>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Category:</label>
              <select
                name="companyCategoryId"
                id="companyCategoryId"
                value={companydata?.companyCategoryId}
                onChange={changedata1}
              >
                <option value="">--select--</option>
                {companyCategories?.length > 0 &&
                  companyCategories.map((item) => {
                    return (
                      <option value={item._id} key={item._id}>
                        {item.categoryName}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="form-col">
              <label className="mandatory-label"> Name:</label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={companydata.companyName}
                onChange={changedata1}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Company Code:</label>
              <input
                type="text"
                id="companyCode"
                name="companyCode"
                value={companydata.companyCode}
                onChange={changedata1}
              />
            </div>
            <div className="form-col">
              <label className="mandatory-label">Address Line 1 :</label>
              <input
                type="text"
                id="address1"
                name="address1"
                value={companydata.address1}
                onChange={changedata1}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Address Line 2 :</label>
              <input
                type="text"
                id="address2"
                name="address2"
                value={companydata.address2}
                onChange={changedata1}
              />
            </div>
            <div className="form-col">
              <label className="mandatory-label">Country :</label>
              <select value={countryName} onChange={handleCountryChange}>
                <option value="">--select--</option>
                {Countries?.length > 0 &&
                  Countries.map((item, index) => {
                    return (
                      <option value={item.locationName} key={index}>
                        {item.locationName}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label htmlFor="countryName">City :</label>
              <select
                name="stateCityId"
                id="stateCityId"
                value={companydata.stateCityId}
                onChange={changedata1}
              >
                <option value="">--select--</option>
                {cities?.length > 0 &&
                  cities.map((item) => {
                    return (
                      <option value={item._id} key={item._id}>
                        {item.locationName}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="form-col">
              <label htmlFor="currency">Pincode :</label>
              <input
                type="number"
                id="pinCode"
                name="pinCode"
                value={companydata.pinCode}
                onChange={changedata1}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Company Name on Batch :</label>
              <input
                type="text"
                name="companyNameOnBatch"
                id="companyNameOnBatch"
                value={companydata.companyNameOnBatch}
                onChange={changedata1}
              />
            </div>
            <div className="form-col">
              <label htmlFor="currency">ISD Code :</label>
              <input
                type="number"
                id="isdCode"
                name="isdCode"
                value={companydata.isdCode}
                onChange={changedata1}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <button className="btn">{isEditable ? "Save" : "Submit"}</button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddCompany;
