import React from "react";
import { Navigate, Outlet } from "react-router";

const Protected = ({ isAuthenticated }) => {
  if (!isAuthenticated) {
    return <Navigate to={"/login"} />;
  }
  return <Outlet />;
};

export default Protected;
