import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { getAllCompany } from "../../../../api/companyMaster";
import {
  createSpeaker,
  getSpeakerById,
  updateSpeaker,
} from "../../../../api/speaker";
import { getEvents } from "../../../../api/event";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const AddNewSpeaker = ({ isEditable }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedfileForEdit, setSelectedFileforEdit] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [listAsspeaker, setListAsSpeaker] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [formData, setFormData] = useState({
    speakerTitle: "",
    speakerName: "",
    companyId: "",
    designation: "",
    email: "",
    contactNo: "",
    biography: "",
    showInOrder: "",
    speakerPaperTitle: "",
    speakerPaperDescription: "",
    speakerPaperDate : "",
  });
  const params = useParams();
  const dispatch = useDispatch();

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "image" && e.target.files[0]) {
      setSelectedFileforEdit(null);
      setSelectedFile(e.target.files[0]);
    }

    setFormData({ ...formData, [name]: value });
  };
  const handleCompanychange = (e) => {
    const company = companies.find((item) => {
      return item.companyName === e.target.value;
    });
    setCompanyName(company.companyName);
    setFormData({ ...formData, companyId: company._id });
  };

  const handleCheckbox = (e) => {
    if (e.target.name === "isPublished") {
      setIsPublished(!isPublished);
    } else if (e.target.name === "listAsspeaker") {
      setListAsSpeaker(!listAsspeaker);
    }
  };

  useEffect(() => {
    fetchAllCompanies();
    setCompanyName("");
    setSelectedFileforEdit(null);
    setSelectedFile(null);
    setIsPublished("");
    setListAsSpeaker("");
    setFormData({
      speakerTitle: "",
      speakerName: "",
      companyId: "",
      designation: "",
      email: "",
      contactNo: "",
      biography: "",
      showInOrder: "",
      speakerPaperTitle: "",
      speakerPaperDescription: "",
      speakerPaperDate: "",
    });
    async function fetchAllCompanies() {
      try {
        const data = await getAllCompany();
        setCompanies(data.data);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
  }, [isEditable]);
  useEffect(() => {
    if (isEditable) {
      const id = params.id;
      fetchSpeakerById(id);
      async function fetchSpeakerById(id) {
        try {
          const data = await getSpeakerById(id);
          setCompanyName(data.data.companyId.companyName);
          setSelectedFileforEdit(data.data.profilePic);
          setSelectedFile(null);
          setIsPublished(data.data.isPublished);
          setListAsSpeaker(data.data.listAsspeaker);
          setFormData({
            speakerTitle: data.data.speakerTitle,
            speakerName: data.data.speakerName,
            companyId: data.data.companyId._id,
            designation: data.data.designation,
            email: data.data.email,
            contactNo: data.data.contactNo,
            biography: data.data.biography,
            showInOrder: data.data.showInOrder,
            speakerPaperTitle : data.data.speakerPaperTitle,
            speakerPaperDescription : data.data.speakerPaperDescription,
            speakerPaperDate : data.data.speakerPaperDate,
          });
        } catch (error) {
          if (error.message === "Please ReLogin") {
            toast.error(error.message);
            localStorage.removeItem("userType");
            localStorage.removeItem("token");
            dispatch({ type: "logout" });
          }
          toast.error(error.message);
        }
      }
    }
  }, [isEditable, params.id]);

  const submit = async (e) => {
    e.preventDefault();
    if (!isEditable) {
      try {
        const events = await getEvents();
        const newFormData = new FormData();
        for (const key in formData) {
          newFormData.append(key, formData[key]);
        }

        newFormData.append("image", selectedFile);

        newFormData.append("isPublished", isPublished);
        newFormData.append("listAsspeaker", listAsspeaker);
        newFormData.append("eventId", events.data[0]._id);
        const data = await createSpeaker(newFormData);
        toast.success(data.message);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    } else {
      try {
        const events = await getEvents();
        const newFormData = new FormData();
        for (const key in formData) {
          newFormData.append(key, formData[key]);
        }
        newFormData.delete("image");
        newFormData.append("image", selectedFile);
        newFormData.append("isPublished", isPublished);
        newFormData.append("listAsspeaker", listAsspeaker);
        newFormData.append("eventId", events.data[0]._id);
        const data = await updateSpeaker(newFormData, params.id);
        toast.success(data.message);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
  };

  return (
    <div className={`yourComponent ${isExpanded ? "expanded" : ""}`}>
      <div className="horizontalLine"></div>
      <div className="header">
        <div className="title">Speaker Details</div>
        <button className="expandButton" onClick={toggleExpand}>
          {isExpanded ? "-" : "+"}
        </button>
      </div>
      {isExpanded && (
        <form className="form-container" onSubmit={submit}>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Speaker Title :</label>
              <input
                type="text"
                name="speakerTitle"
                id="speakerTitle"
                onChange={handleOnChange}
                value={formData.speakerTitle}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Speaker Name :</label>
              <input
                type="text"
                required
                onChange={handleOnChange}
                name="speakerName"
                id="speakerName"
                value={formData.speakerName}
              />
            </div>
            <div className="form-col">
              <label>Company Name :</label>
              <select onChange={handleCompanychange} value={companyName}>
                <option value="">--select--</option>
                {companies.map((item, index) => {
                  return (
                    <option value={item.companyName} key={index}>
                      {item.companyName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Designation :</label>
              <input
                type="text"
                // required
                onChange={handleOnChange}
                name="designation"
                id="designation"
                value={formData.designation}
              />
            </div>
            <div className="form-col">
              <label>Email :</label>
              <input
                type="email"
                // required
                onChange={handleOnChange}
                name="email"
                id="email"
                value={formData.email}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-col">
              <label>Contact No :</label>
              <input
                type="tel"
                // required
                name="contactNo"
                id="contactNo"
                value={formData.contactNo}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Profile Pic :</label>
              <input
                type="file"
                onChange={handleOnChange}
                name="image"
                id="image"
                value={formData.image}
              />
              {selectedfileForEdit && (
                <div className="h-16 w-16">
                  <img
                    src={selectedfileForEdit}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
              )}
            </div>
            <div className="form-col">
              <label>Biography :</label>
              <textarea
                id="biography"
                name="biography"
                rows="4"
                cols="50"
                onChange={handleOnChange}
                value={formData.biography}
              ></textarea>
            </div>
          </div>
          <div className="form-row">
            <div className="form-col checkbox-style">
              <label htmlFor="checkbox">Is Published:</label>
              <input
                type="checkbox"
                id="isPublished"
                name="isPublished"
                onChange={handleCheckbox}
                checked={isPublished}
                style={{ height: "14px" }}
              />
            </div>

            <div className="form-col">
              <label>Show In Order :</label>
              <input
                type="text"
                name="showInOrder"
                id="showInOrder"
                onChange={handleOnChange}
                value={formData.showInOrder}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col checkbox-style">
              <label htmlFor="checkbox">List As Speaker :</label>
              <input
                type="checkbox"
                id="listAsspeaker"
                name="listAsspeaker"
                onChange={handleCheckbox}
                checked={listAsspeaker}
                style={{ height: "14px" }}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Speaker Paper Title :</label>
              <input
                type="text"
                // required
                name="speakerPaperTitle"
                id="speakerPaperTitle"
                value={formData.speakerPaperTitle}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Speaker Paper Description </label>
              <input
                type="text"
                // required
                name="speakerPaperDescription"
                id="speakerPaperDescription"
                value={formData.speakerPaperDescription}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Speaker Paper Date :</label>
              <input
                type="text"
                // required
                name="speakerPaperDate"
                id="speakerPaperDate"
                value={formData.speakerPaperDate}
                onChange={handleOnChange}
              />
            </div>
          </div>
          
          <div className="form-row">
            <div className="form-col">
              <button className="btn">{isEditable ? "Save" : "Submit"}</button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddNewSpeaker;
