import axios from "axios";
// const token = localStorage.getItem("token");
const baseURL = process.env.REACT_APP_AWS_URL;
export const createSpeaker = async (formData) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(`${baseURL}/api/v1/Speaker`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const getSpeakerById = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(`${baseURL}/api/v1/Speaker/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const updateSpeaker = async (formData, id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.put(
      `${baseURL}/api/v1/Speaker/${id}`,
      formData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const getAllSpeakers = async () => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(
      `${baseURL}/api/v1/Speaker`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};
