import axios from "axios";
const baseURL = process.env.REACT_APP_AWS_URL;
export const authUser = async (formData) => {
  try {
    const { data } = await axios.post(
      `${baseURL}/api/v1/admin/login`,
      formData
    );
    return data;
  } catch (error) {
    console.log("error", error);
    const message = error.response.data.error || error.response.data.message;
    throw new Error(message);
  }
};
