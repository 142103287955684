import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { getNames } from "country-list";
import { useTable, usePagination } from "react-table";
import {
  createSponsor,
  getSponsorById,
  updateSponsor,
} from "../../../../api/sponsor";
import {
  getAllCitiesByCountryId,
  getAllCountries,
} from "../../../../api/locationMaster";
import { getEvents } from "../../../../api/event";
import { useParams } from "react-router";
import { getSpeakerById } from "../../../../api/speaker";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

// Sample data
const data = [
  {
    id: 1,
    sponsorProductId: "Speaker",
    productName: "INR",
  },

  // Add more data here
];

const AddNewSponsors = ({ isEditable }) => {
  const [selectedfileForEdit, setSelectedFileforEdit] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);
  const [formData, setFormData] = useState({
    sponserType: "",
    sponserName: "",
    sponserShortname: "",
    sponserLabel: "",
    sponserAddress: "",
    sponserCountryId: "",
    sponserCityId: "",
    pinCode: "",
    sponserDescription: "",
    sponserFromDate: "",
    sponserToDate: "",
    sponserWebUrl: "",
    contactPerson: "",
    contactPersonNo: "",
    showInOrder: "",
    meetAt: "",
  });
  const [isPublished, setIsPublished] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!isEditable) {
      try {
        setLoading(true);
        console.log(formData);
        const events = await getEvents();
        const newFormData = new FormData();
        for (const key in formData) {
          newFormData.append(key, formData[key]);
        }
        newFormData.append("image", selectedFile);
        newFormData.append("isPublished", isPublished);
        newFormData.append("eventId", events.data[0]._id);
        const data = await createSponsor(newFormData);
        setLoading(false);
        toast.success(data.message);
      } catch (error) {
        setLoading(false);
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    } else {
      try {
        setLoading(true);
        const events = await getEvents();
        const newFormData = new FormData();
        for (const key in formData) {
          newFormData.append(key, formData[key]);
        }
        newFormData.append("image", selectedFile);
        newFormData.append("isPublished", isPublished);
        newFormData.append("eventId", events.data[0]._id);
        const data = await updateSponsor(newFormData, params.id);
        setLoading(false);
        toast.success(data.message);
      } catch (error) {
        setLoading(false);
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setSelectedFileforEdit(null);
      setSelectedFile(e.target.files[0]);
    }
    console.log(value);
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckBox = () => {
    setIsPublished(!isPublished);
  };

  const handleCountryChange = (e) => {
    setFormData({ ...formData, sponserCountryId: e.target.value });
    fetchAllCities(e.target.value);
  };

  const fetchAllCities = async (id) => {
    try {
      const data = await getAllCitiesByCountryId(id);

      setCities(data.data.data);
    } catch (error) {
      setCities([]);
      if (error.message === "Please ReLogin") {
        toast.error(error.message);
        localStorage.removeItem("userType");
        localStorage.removeItem("token");
        dispatch({ type: "logout" });
      }
      toast.error(error.message);
      // console.log(error.message);
    }
  };

  const handleCityChange = (e) => {
    setFormData({ ...formData, sponserCityId: e.target.value });
  };

  useEffect(() => {
    fetchAllCountries();

    async function fetchAllCountries() {
      try {
        const data = await getAllCountries();
        setCountries(data.data);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
  }, []);

  useEffect(() => {
    if (isEditable) {
      const id = params.id;
      fetchSponsorById(id);
      async function fetchSponsorById(id) {
        try {
          const data = await getSponsorById(id);
          setSelectedFileforEdit(data.data?.sponserLogo);
          setSelectedFile(null);
          setIsPublished(data.data?.isPublished);
          setFormData({
            sponserType: data.data?.sponserType,
            sponserName: data.data?.sponserName,
            sponserShortname: data.data?.sponserShortname,
            sponserLabel: data.data?.sponserLabel,
            sponserAddress: data.data?.sponserAddress,
            sponserCountryId: data.data?.sponserCountryId?._id,
            sponserCityId: data.data?.sponserCityId?._id,
            pinCode: data.data?.pinCode,
            sponserDescription: data.data?.sponserDescription,
            sponserFromDate: data.data?.sponserFromDate.slice(0, 10),
            sponserToDate: data.data?.sponserToDate.slice(0, 10),
            sponserWebUrl: data.data?.sponserWebUrl,
            contactPerson: data.data?.contactPerson,
            contactPersonNo: data.data?.contactPersonNo,
            showInOrder: data.data?.showInOrder,
            meetAt: data.data?.meetAt,
          });
          fetchAllCities(data.data?.sponserCountryId?._id);
        } catch (error) {
          if (error.message === "Please ReLogin") {
            toast.error(error.message);
            localStorage.removeItem("userType");
            localStorage.removeItem("token");
            dispatch({ type: "logout" });
          }
          toast.error(error.message);
        }
      }
    } else {
      setSelectedFileforEdit(null);
      setSelectedFile(null);
      setIsPublished(false);
      setFormData({
        sponserType: "",
        sponserName: "",
        sponserShortname: "",
        sponserLabel: "",
        sponserAddress: "",
        sponserCountryId: "",
        sponserCityId: "",
        pinCode: "",
        sponserDescription: "",
        sponserFromDate: "",
        sponserToDate: "",
        sponserWebUrl: "",
        contactPerson: "",
        contactPersonNo: "",
        showInOrder: "",
        meetAt: "",
      });
    }
  }, [isEditable, params.id]);

  return (
    <div className={`yourComponent ${isExpanded ? "expanded" : ""}`}>
      <div className="horizontalLine"></div>
      <div className="header">
        <div className="title">Sponsors Details</div>
        <button className="expandButton" onClick={toggleExpand}>
          {isExpanded ? "-" : "+"}
        </button>
      </div>
      {isExpanded && (
        <form className="form-container" onSubmit={submit}>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Sponsor Type :</label>
              <select
                required
                name="sponserType"
                id="sponserType"
                value={formData.sponserType}
                onChange={handleOnChange}
              >
                <option value="">--select--</option>
                <option value="Diamond">Diamond</option>
                <option value="Gold">Gold</option>
                <option value="Silver">Silver</option>
                <option value="Others">Others</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Sponsor Name :</label>
              <input
                type="text"
                required
                name="sponserName"
                id="sponserName"
                value={formData.sponserName}
                onChange={handleOnChange}
              />
            </div>
            <div className="form-col">
              <label>Sponsor Short Name :</label>
              <input
                type="text"
                name="sponserShortname"
                id="sponserShortname"
                value={formData.sponserShortname}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Sponsor Label :</label>
              <input
                type="text"
                name="sponserLabel"
                id="sponserLabel"
                value={formData.sponserLabel}
                onChange={handleOnChange}
              />
            </div>
            <div className="form-col">
              <label>Sponsor Address :</label>
              <textarea
                rows="4"
                cols="50"
                name="sponserAddress"
                id="sponserAddress"
                value={formData.sponserAddress}
                onChange={handleOnChange}
              ></textarea>
            </div>
          </div>

          <div className="form-row">
            <div className="form-col">
              <label htmlFor="countryName" className="mandatory-label">
                Country :
              </label>
              <select
                required
                name="sponserCountryId"
                id="sponserCountryId"
                multiple={false}
                value={formData.sponserCountryId}
                onChange={handleCountryChange}
              >
                <option value="">--select--</option>
                {countries.map((country, index) => (
                  <option key={index} value={country._id}>
                    {country.locationName}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-col">
              <label className="mandatory-label">Sponsor City :</label>
              <select
                name="sponserCityId"
                id="sponserCityId"
                multiple={false}
                value={formData.sponserCityId}
                onChange={handleCityChange}
                required
              >
                <option value="">--select--</option>
                {cities?.length > 0 &&
                  cities.map((city, index) => (
                    <option key={index} value={city._id}>
                      {city.locationName}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Pin Code :</label>
              <input
                type="number"
                required
                name="pinCode"
                id="pinCode"
                value={formData.pinCode}
                onChange={handleOnChange}
              />
            </div>
            <div className="form-col">
              <label>Sponsor Logo :</label>
              <input
                type="file"
                onChange={handleOnChange}
                name="image"
                id="image"
                // value={formData.image}
              />
              {selectedfileForEdit && (
                <div className="h-16 w-16">
                  <img
                    src={selectedfileForEdit}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Sponsor Description :</label>
              <textarea
                rows="4"
                cols="50"
                name="sponserDescription"
                id="sponserDescription"
                value={formData.sponserDescription}
                onChange={handleOnChange}
              ></textarea>
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Sponser From Date :</label>
              <input
                type="date"
                name="sponserFromDate"
                id="sponserFromDate"
                value={formData.sponserFromDate}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Sponsor To Date :</label>
              <input
                type="date"
                name="sponserToDate"
                id="sponserToDate"
                value={formData.sponserToDate}
                onChange={handleOnChange}
              />
            </div>
            <div className="form-col">
              <label>Sponser Web Url :</label>
              <input
                type="text"
                name="sponserWebUrl"
                id="sponserWebUrl"
                value={formData.sponserWebUrl}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Contact Person :</label>
              <input
                type="text"
                name="contactPerson"
                id="contactPerson"
                value={formData.contactPerson}
                onChange={handleOnChange}
              />
            </div>
            <div className="form-col">
              <label>Contact Person No :</label>
              <input
                type="tel"
                name="contactPersonNo"
                id="contactPersonNo"
                value={formData.contactPersonNo}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col checkbox-style">
              <label htmlFor="checkbox">Is Published:</label>
              <input
                type="checkbox"
                id="isPublished"
                name="isPublished"
                onChange={handleCheckBox}
                checked={isPublished}
                style={{ height: "14px" }}
              />
            </div>
            <div className="form-col">
              <label>Show In Order :</label>
              <input
                type="number"
                name="showInOrder"
                id="showInOrder"
                value={formData.showInOrder}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Meet At :</label>
              <input
                type="text"
                name="meetAt"
                id="meetAt"
                value={formData.meetAt}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className={`form-col ${loading ? " opacity-60" : ""}`}>
              <button className="btn">{isEditable ? "Save" : "Submit"}</button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddNewSponsors;
