import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  createCategory,
  getCompanyCategoryById,
  updateCategory,
} from "../../../../api/companyCategory";
import { useNavigate, useParams } from "react-router";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const AddCompanyCategory = ({ isEditable }) => {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(true);
  const [alldata, setAlldata] = useState({
    categoryName: "",
    currency: "",
    seminarFee: "",
    isPublished: false,
  });
  const navigate = useNavigate();

  const params = useParams();
  const changedata = (e) => {
    const { name, value, checked, type } = e.target;
    setAlldata((prevState) => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value };
    });
  };

  const Submit = async (e) => {
    e.preventDefault();
    if (!isEditable) {
      try {
        const data = await createCategory(alldata);
        toast.success(data.message);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          //redirect to login page
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    } else {
      try {
        console.log(alldata);
        const data = await updateCategory(alldata, params.id);
        toast.success(data.message);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          //redirect to login page
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const id = params.id;

    if (isEditable) {
      fetchCompanyCategory(id);
      async function fetchCompanyCategory() {
        try {
          console.log("hello");
          const data = await getCompanyCategoryById(id);
          setAlldata({
            categoryName: data.data?.categoryName,
            currency: data.data?.currency,
            seminarFee: data.data?.seminarFee,
            isPublished: data.data?.isPublished,
          });
        } catch (error) {
          if (error.message === "Please ReLogin") {
            //redirect to login page
            toast.error(error.message);
            localStorage.removeItem("userType");
            localStorage.removeItem("token");
            dispatch({ type: "logout" });
          }
          toast.error(error.message);
        }
      }
    } else {
      setAlldata({
        categoryName: "",
        currency: "",
        seminarFee: "",
        isPublished: "",
      });
    }
  }, [params.id, isEditable]);

  return (
    <div className={`yourComponent ${isExpanded ? "expanded" : ""}`}>
      <div className="horizontalLine"></div>
      <div className="header">
        <div className="title">Category Details</div>
        <button className="expandButton" onClick={toggleExpand}>
          {isExpanded ? "-" : "+"}
        </button>
      </div>
      {isExpanded && (
        <form className="form-container" onSubmit={Submit} id="company">
          <div className="form-row">
            <div className="form-col">
              <label htmlFor="categoryName" className="mandatory-label">
                Category Name:
              </label>
              <input
                value={alldata.categoryName}
                type="text"
                id="categoryName"
                onChange={changedata}
                name="categoryName"
              />
            </div>
            <div className="form-col">
              <label htmlFor="currency" className="mandatory-label">
                Currency:
              </label>
              <input
                value={alldata.currency}
                type="text"
                id="currency"
                onChange={changedata}
                name="currency"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label htmlFor="seminarFee" className="mandatory-label">
                Seminar Fee:
              </label>
              <input
                value={alldata.seminarFee}
                type="text"
                id="seminarFee"
                name="seminarFee"
                onChange={changedata}
              />
            </div>
            <div className="form-col checkbox-style">
              <label htmlFor="checkbox">Is Published:</label>
              <input
                type="checkbox"
                id="isPublished"
                name="isPublished"
                onChange={changedata}
                checked={alldata.isPublished}
                style={{ height: "14px" }}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <button type="submit" className="btn">
                {isEditable ? "Save" : "Submit"}
              </button>
            </div>
          </div>
        </form>
      )}
      {/* <div className="list-tem-main">hi</div> */}
    </div>
  );
};

export default AddCompanyCategory;
