import React, { useEffect, useState } from "react";
import "./styles.css";
import { toast } from "react-toastify";
import {
  createLocation,
  getAllCountries,
  getAllStates,
  getLocationById,
  updateLocation,
} from "../../../../api/locationMaster";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

const AddLocation = ({ isEditable }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [optionsArray, setOptionsArray] = useState([]);
  const params = useParams();
  const dispatch = useDispatch();

  const [alldata, setAlldata] = useState({
    locationType: "",
    countryState: "",
    locationName: "",
  });

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const changedata = (e) => {
    const { name, value } = e.target;
    setAlldata({ ...alldata, [name]: value });
  };

  const Submit = async (e) => {
    e.preventDefault();
    if (!isEditable) {
      try {
        const data = await createLocation(alldata);
        toast.success(data.message);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          //redirect to login page
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    } else {
      try {
        const data = await updateLocation(alldata, params.id);
        toast.success(data.message);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          //redirect to login page
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    const Location = alldata.locationType;
    if (Location === "State") {
      fetchAllCountries();
    } else if (Location === "City") {
      fetchAllStates();
    }
  }, [alldata.locationType]);

  async function fetchAllStates() {
    try {
      const data = await getAllStates();
      setOptionsArray(data.data);
    } catch (error) {
      if (error.message === "Please ReLogin") {
        //redirect to login page
        toast.error(error.message);
        localStorage.removeItem("userType");
        localStorage.removeItem("token");
        dispatch({ type: "logout" });
      }
      toast.error(error.message);
    }
  }
  async function fetchAllCountries() {
    try {
      const data = await getAllCountries();
      setOptionsArray(data.data);
    } catch (error) {
      if (error.message === "Please ReLogin") {
        //redirect to login page
        toast.error(error.message);
        localStorage.removeItem("userType");
        localStorage.removeItem("token");
        dispatch({ type: "logout" });
      }
      toast.error(error.message);
    }
  }
  useEffect(() => {
    const id = params.id;
    if (isEditable) {
      fetchLocationbyId(id);
      async function fetchLocationbyId(id) {
        try {
          const data = await getLocationById(id);
          if (data.data.data?.locationType === "State") {
            setAlldata({
              locationType: data.data.data?.locationType,
              countryState: data.data.data?.country._id,
              locationName: data.data.data?.locationName,
            });

            fetchAllCountries();
          } else if (data.data.data?.locationType === "Country") {
            setAlldata({
              locationType: data.data.data?.locationType,
              countryState: "",
              locationName: data.data.data?.locationName,
            });
          } else {
            setAlldata({
              locationType: data.data?.data?.locationType,
              countryState: data.data?.data?.state?._id,
              locationName: data.data?.data?.locationName,
            });
            fetchAllStates();
          }
        } catch (error) {
          if (error.message === "Please ReLogin") {
            //redirect to login page
            toast.error(error.message);
            localStorage.removeItem("userType");
            localStorage.removeItem("token");
            dispatch({ type: "logout" });
          }
          toast.error(error.message);
        }
      }
    } else {
      setAlldata({
        locationType: "",
        countryState: "",
        locationName: "",
      });
    }
  }, [isEditable, params.id]);

  return (
    <div className={`yourComponent ${isExpanded ? "expanded" : ""}`}>
      <div className="horizontalLine"></div>
      <div className="header">
        <div className="title">Location Details</div>
        <button className="expandButton" onClick={toggleExpand}>
          {isExpanded ? "-" : "+"}
        </button>
      </div>
      {
        isExpanded && (
          <form className="form-container" id="location" onSubmit={Submit}>
            <div className="form-row">
              <div className="form-col">
                <label className="mandatory-label">Select Type:</label>

                {isEditable ? (
                  <input
                    name="locationType"
                    id="locationType"
                    value={alldata?.locationType}
                    disabled

                    // onChange={changedata}
                  ></input>
                ) : (
                  <select
                    name="locationType"
                    id="locationType"
                    value={alldata?.locationType}
                    onChange={changedata}
                  >
                    <option value="">--select--</option>
                    <option value="Country">Country</option>

                    <option value="State">State</option>

                    <option value="City">City</option>
                  </select>
                )}
              </div>
              <div className="form-col">
                <label className="mandatory-label">Location Name:</label>
                <input
                  type="text"
                  id="locationName"
                  name="locationName"
                  value={alldata.locationName}
                  onChange={changedata}
                />
              </div>
            </div>
            {/* {Countryst ? (
              <AddCountry />
            ) : ( */}
            <div className="form-row">
              <div className="form-col">
                <label className="mandatory-label">Country/State:</label>
                <select
                  disabled={
                    alldata.locationType !== "Country" &&
                    alldata.locationType !== "City" &&
                    alldata.locationType !== "State"
                  }
                  className={`${
                    alldata.locationType !== "Country" &&
                    alldata.locationType !== "City" &&
                    alldata.locationType !== "State"
                      ? "bg-slate-300"
                      : ""
                  }`}
                  name="countryState"
                  id="countryState"
                  value={alldata?.countryState}
                  onChange={changedata}
                >
                  <option value="">--select--</option>
                  {alldata.locationType === "Country" ? (
                    <option>NONE</option>
                  ) : alldata.locationType === "State" ||
                    alldata.locationType === "City" ? (
                    optionsArray?.length > 0 &&
                    optionsArray.map((item, index) => {
                      return (
                        <>
                          <option value={item._id} key={index}>
                            {item.locationName}
                          </option>
                        </>
                      );
                    })
                  ) : (
                    <option>--select--</option>
                  )}
                </select>
              </div>
            </div>
            {/* )} */}
            <div className="form-row">
              <div className="form-col">
                <button type="submit" className="btn">
                  {isEditable ? "Save" : "Submit"}
                </button>
              </div>
            </div>
          </form>
        )

        // city-wala-code
      }
    </div>
  );
};

export default AddLocation;
