import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { getNames } from "country-list";
import { State, City } from "country-state-city";
import { getAllCompany, getCompanyById } from "../../../../api/companyMaster";
import {
  getCompanyCategory,
  getCompanyCategoryById,
} from "../../../../api/companyCategory";
import {
  getAllStatesbyCityId,
  getAllCountries,
  getAllStatesbyCountryId,
  getLocationById,
} from "../../../../api/locationMaster";
import {
  createDelegate,
  getDelegateById,
  updateDelegate,
} from "../../../../api/delegate";
import { useParams } from "react-router";
import { Category } from "@mui/icons-material";
import { create } from "@mui/material/styles/createTransitions";
import { getEvents } from "../../../../api/event";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const AddNewDelegate = ({ isEditable }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [cityName,  setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [companies, setCompanies] = useState([]);
  // const [company, setCompany] = useState([]);
  const [companyName, setCompanyName] = useState("");
  //   const [companyCode, setCompanyCode] = useState("");
  //   const [companyCodeName, setCompanyCodeName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  // const [delegateCategoryArray, setDelegateCategoryArray] = useState([]);
  const [delegateCategory, setDelegateCategory] = useState({});
  //   const [formData, setFormData] = useState(new FormData());
  const [selectedfile, setSelectedFile] = useState(null);
  const [selectedfileForEdit, setSelectedFileforEdit] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showContactNo, setShowContactNo] = useState(false);
  const [openForAppointment, setOpenForAppointment] = useState(false);
  const [sendMail, setSendMail] = useState(false);
  const [payment, setPayment] = useState(false);
  const [companyCode, setCompanyCode] = useState("");
  const [sendPassword, setSendPassword] = useState(false);

  const [formData, setFormData] = useState({
    companyId: "",
    delegateCategoryId: "",
    email: "",
    otherEmail: "",
    delegateTitle: "",
    firstName: "",
    middleName: "",
    lastName: "",
    delegateLoginId: "",
    delegatePassword: "",
    address1: "",
    address2: "",
    countryId: "",
    cityId: "",
    pinCode: "",
    mobileNumber: "",
    designation: "",
    aboutMySelf: "",
    receiptNo: "",
    sponsorer: "",
    currency: "",
    seminarFee: "",
    remarks: "",
    registrationNo: "",
    showInOrder: "",
  });

  const params = useParams();
  const dispatch = useDispatch();
  const userType = localStorage.getItem("userType");

  const handleCheckbox = (e) => {
    if (e.target.name === "isPublished") {
      setIsPublished(!isPublished);
    }
    if (e.target.name === "showEmail") {
      setShowEmail(!showEmail);
    }
    if (e.target.name === "showContactNo") {
      setShowContactNo(!showContactNo);
    }
    if (e.target.name === "openForAppointment") {
      setOpenForAppointment(!openForAppointment);
    }
    if (e.target.name === "sendMail") {
      setSendMail(!sendMail);
    }
    if (e.target.name === "payment") {
      setPayment(!payment);
    }
  };

  const handleChange = (e) => {
    const { type, name, value } = e.target;
    if (type === "checkbox") {
      setFormData((prevState) => {
        return {
          ...formData,
          [name]: !prevState.name,
        };
      });
      //   setFormData({
      //     ...formData,
      //     [name]: value === "on" ? true : false,
      //   });
    }
    if (name === "image" && e.target.files[0]) {
      setSelectedFileforEdit(null);
      setSelectedFile(e.target.files[0]);
    }
    setFormData({ ...formData, [name]: value });
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const fetchCompanyNameAndDelegateCategory = async (e) => {
    const comp = companies.find((element) => {
      return element.companyCode === e.target.value;
    });
    const comp_2 = await getCompanyById(comp._id);
    const state_comp_2 = await getLocationById(comp_2.data.stateCityId._id);
    // setCompany(comp);
    setCompanyName(comp.companyName);
    setCompanyCode(comp.companyCode);
    // // setCompanyCodeName(comp.companyCode);
    // console.log(comp_2);
    // console.log(state_comp_2);
    // console.log(state_comp_2.data.data.state);
    try {
      const data = await getCompanyCategoryById(comp.companyCategoryId);
      // const data = await getCompanyById(comp.companyCategoryId);
      // console.log(data);
      fetchStateByCountry(comp_2.data.countryId._id);
      setCountryName(comp_2.data.countryId.locationName);
      fetchCityByState(state_comp_2.data.data.state._id);
      setStateName(state_comp_2.data.data.state._id);
      setCityName(comp_2.data.stateCityId.locationName);
      setDelegateCategory(data.data);
      setCategoryName(data.data.categoryName);
      setFormData({
        ...formData,
        companyId: comp._id,
        delegateCategoryId: data.data._id,
        currency: data.data.currency,
        seminarFee: data.data.seminarFee,
        address1: comp.address1,
        address2: comp.address2,
        pinCode: comp.pinCode,
        countryId: comp_2.data.countryId._id,
        cityId: comp_2.data.stateCityId._id
      });
    } catch (error) {
      if (error.message === "Please ReLogin") {
        toast.error(error.message);
        localStorage.removeItem("userType");
        localStorage.removeItem("token");
        dispatch({ type: "logout" });
      }
      toast.error(error.message);
    }
  };

  const handleCountryChange = (e) => {
    const { _id } = countries.find((id) => id.locationName === e.target.value);
    fetchStateByCountry(_id);
    setFormData({ ...formData, countryId: _id });
    setCountryName(e.target.value);
  };

  const handleEmailChange = (e) => {
    const { type, name, value } = e.target;
    console.log(value);
    setFormData({ ...formData, delegateLoginId: value, [name]: value });
  }

  const fetchStateByCountry = async (id) => {
    try {
      const data = await getAllStatesbyCountryId(id);
      setStates(data.data);
    } catch (error) {
      if (error.message === "Please ReLogin") {
        toast.error(error.message);
        localStorage.removeItem("userType");
        localStorage.removeItem("token");
        dispatch({ type: "logout" });
      }
      toast.error(error.message);
    }
  };

  const handleStateChange = (e) => {
    fetchCityByState(e.target.value);
    setStateName(e.target.value);
    setFormData({ ...formData, stateId: e.target.value });
  };

  const fetchCityByState = async (id) => {
    try {
      const data = await getAllStatesbyCityId(id);
      setCities(data.data);
    } catch (error) {
      if (error.message === "Please ReLogin") {
        toast.error(error.message);
        localStorage.removeItem("userType");
        localStorage.removeItem("token");
        dispatch({ type: "logout" });
      }
      toast.error(error.message);
    }
  };

  const handleCityChange = (e) => {
    const city = cities.find((id) => id.locationName === e.target.value);
    setFormData({ ...formData, cityId: city._id });
    setCityName(city.locationName);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!isEditable) {
      try {
        setFormData({ ...formData, isPublished: isPublished });
        setFormData({ ...formData, showEmail: showEmail });
        setFormData({ ...formData, showContactNo: showContactNo });
        setFormData({ ...formData, openForAppointment: openForAppointment });
        setFormData({ ...formData, sendMail: sendMail });
        setFormData({ ...formData, payment: payment });
        const events = await getEvents();
        const newFormData = new FormData();
        for (const key in formData) {
          newFormData.append(key, formData[key]);
        }
        newFormData.delete("payment");
        newFormData.append("isPublished", isPublished);
        newFormData.append("showEmail", showEmail);
        newFormData.append("showContactNo", showContactNo);
        newFormData.append("openForAppointment", openForAppointment);
        newFormData.append("sendMail", sendMail);
        newFormData.append("payment", payment);
        newFormData.append("image", selectedfile);
        newFormData.append("eventId", events.data[0]._id);
        const data = await createDelegate(newFormData);
        toast.success(data.message);
        setFormData({...formData, delegatePassword: (Math.random() + 1).toString(36).substring(7)});
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    } else {
      try {
        const events = await getEvents();
        const newFormData = new FormData();
        for (const key in formData) {
          newFormData.append(key, formData[key]);
        }
        newFormData.delete("image");
        newFormData.delete("isPublished");
        newFormData.delete("showEmail");
        newFormData.delete("showContactNo");
        newFormData.delete("openForAppointment");
        newFormData.delete("sendMail");
        newFormData.delete("payment");
        newFormData.delete("eventId");
        newFormData.append("image", selectedfile);
        newFormData.append("isPublished", isPublished);
        newFormData.append("showEmail", showEmail);
        newFormData.append("showContactNo", showContactNo);
        newFormData.append("openForAppointment", openForAppointment);
        newFormData.append("sendMail", sendMail);
        newFormData.append("payment", payment);
        newFormData.append("eventId", events.data[0]._id);
        if (!sendPassword) {
          newFormData.delete("delegatePassword");
        }
        // const data = await updateDelegate(newFormData, params.id);

        const data = await updateDelegate(newFormData, params.id);
        toast.success(data.message);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
        //   window.location.reload();
      }
    }
  };

  useEffect(() => {
    fetchCompanyCode();
    fetchAllCountries();
    setFormData({
      companyId: "",
      delegateCategoryId: "",
      email: "",
      otherEmail: "",
      delegateTitle: "",
      firstName: "",
      middleName: "",
      lastName: "",
      delegateLoginId: "",
      delegatePassword: (Math.random() + 1).toString(36).substring(7),
      address1: "",
      address2: "",
      countryId: "",
      cityId: "",
      pinCode: "",
      mobileNumber: "",
      designation: "",
      aboutMySelf: "",
      receiptNo: "",
      sponsorer: "",
      currency: "",
      seminarFee: "",
      remarks: "",
      registrationNo: "",
      showInOrder: "",
    });
    setCompanyName("");
    setCategoryName("");
    setCountryName("");
    setCityName("");
    setSelectedFileforEdit(null);
    setCompanyCode("");
    setIsPublished(false);
    setPayment(false);
    setSendMail(false);
    setShowContactNo(false);
    setOpenForAppointment(false);
    setShowEmail(false);
    setStateName("");
    async function fetchAllCountries() {
      try {
        const data = await getAllCountries();
        setCountries(data.data);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
    async function fetchCompanyCode() {
      try {
        const data = await getAllCompany();
        setCompanies(data.data);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
  }, [isEditable]);

  useEffect(() => {
    if (isEditable) {
      const id = params.id;
      fetchDelegateById(id);
      async function fetchDelegateById(id) {
        try {
          const data = await getDelegateById(id);
          fetchStateByCountry(data.data.countryId._id);
          setCompanyName(data.data.companyId.companyName);
          setCategoryName(data.data.delegateCategoryId.categoryName);
          setCountryName(data.data?.countryId?.locationName);
          setCityName(data.data?.cityId?.locationName);
          setSelectedFileforEdit(data.data?.profilePic);
          setCompanyCode(data.data?.companyId?.companyCode);
          setIsPublished(data.data?.isPublished);
          setPayment(data.data.payment);
          setSendMail(data.data.sendMail);
          setShowContactNo(data.data.showContactNo);
          setOpenForAppointment(data.data.openForAppointment);
          setShowEmail(data.data?.showEmail);
          setFormData({
            companyId: data.data?.companyId._id,
            delegateCategoryId: data.data?.delegateCategoryId._id,
            email: data.data?.email,
            otherEmail: data.data?.otherEmail,
            delegateTitle: data.data?.delegateTitle,
            firstName: data.data?.firstName,
            middleName: data.data?.middleName,
            lastName: data.data?.lastName,
            delegateLoginId: data.data?.delegateLoginId,
            delegatePassword: data.data?.password,
            address1: data.data?.address1,
            address2: data.data?.address2,
            countryId: data.data?.countryId._id,
            cityId: data.data?.cityId._id,
            pinCode: data.data?.pinCode,
            mobileNumber: data.data?.mobileNumber,
            designation: data.data?.designation,
            aboutMySelf: data.data?.aboutMySelf,
            showEmail: false,
            showContactNo: false,
            openForAppointment: false,
            isPublished: false,
            sendMail: false,
            payment: false,
            receiptNo: data.data?.receiptNo,
            sponsorer: data.data?.sponsorer,
            currency: data.data?.currency,
            seminarFee: data.data?.seminarFee,
            remarks: data.data?.remarks,
            registrationNo: data.data?.registrationNo,
            showInOrder: data.data?.showInOrder,
          });
          fetchStateByCountry(data.data?.countryId._id);
          fetchCityByState(data.data?.cityId.state);
          setStateName(data.data?.cityId?.state);
        } catch (error) {
          if (error.message === "Please ReLogin") {
            toast.error(error.message);
            localStorage.removeItem("userType");
            localStorage.removeItem("token");
            dispatch({ type: "logout" });
          }
          toast.error(error.message);
          return;
        }
      }
    }
  }, [isEditable, params.id]);

  return (
    <div className={`yourComponent ${isExpanded ? "expanded" : ""}`}>
      <div className="horizontalLine"></div>
      <div className="header">
        <div className="title">Delegate Details</div>
        <button className="expandButton" onClick={toggleExpand}>
          {isExpanded ? "-" : "+"}
        </button>
      </div>
      {isExpanded && (
        <form className="form-container" onSubmit={submit}>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Company Code :</label>
              <select
                required
                name="companyCode"
                id="companyCode"
                onChange={(e) => {
                  fetchCompanyNameAndDelegateCategory(e);
                }}
                value={companyCode}
              >
                <option value="">--select--</option>
                {companies.length > 0 &&
                  companies.map((item) => {
                    return (
                      <option value={item.companyCode} key={item._id}>
                        {item.companyCode}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Company Name :</label>
              <input type="text" value={companyName} disabled />
            </div>
            <div className="form-col">
              <label className="mandatory-label">Delegate Category :</label>
              <input type="text" value={categoryName} disabled />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Email Id :</label>
              <input
                type="email"
                required
                // onChange={handleChange}
                onChange={handleEmailChange}
                name="email"
                id="email"
                value={formData.email}
              />
            </div>
            <div className="form-col">
              <label>Alternate Email Id :</label>
              <input
                type="email"
                onChange={handleChange}
                name="otherEmail"
                id="otherEmail"
                value={formData.otherEmail}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              {/* <label>Delegate Title :</label> */}
              <input
                type="text"
                onChange={handleChange}
                name="delegateTitle"
                id="delegateTitle"
                value={formData.delegateTitle}
                hidden
              ></input>
            </div>
            <div className="form-col">
              <label className="mandatory-label">First Name:</label>
              {isEditable && (<input
                type="text"
                required
                onChange={handleChange}
                name="firstName"
                id="firstName"
                value={formData.firstName}
              />)}
              {!isEditable && (
              <select
                required
                onChange={handleChange}
                name="firstName"
                id="firstName"
                value={formData.firstName}
                >
                <option value="">--select--</option>
                <option value="Mr.">Mr.</option>
                <option value="Ms.">Ms.</option>
                <option value="Mrs.">Mrs.</option>
                <option value="Dr.">Dr.</option>
                <option value="Shri.">Shri.</option>
                <option value="Smt.">Smt.</option>
                <option value="Capt.">Capt.</option>
                <option value="Capt.">Prof.</option>
              </select>)}
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Middle Name :</label>
              <input
                type="text"
                onChange={handleChange}
                name="middleName"
                id="middleName"
                value={formData.middleName}
              />
            </div>
            <div className="form-col">
              <label>Last Name :</label>
              <input
                type="text"
                onChange={handleChange}
                name="lastName"
                id="lastName"
                value={formData.lastName}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Delegate login Id :</label>
              <input
                type="text"
                required
                onChange={handleChange}
                name="delegateLoginId"
                id="delegateLoginId"
                value={formData.delegateLoginId}
              />
            </div>
            <div className="form-col">
              <label className="mandatory-label">Delegate Password :</label>
              <input
                type="checkbox"
                value={sendPassword}
                onChange={() => {
                  setSendPassword(!sendPassword);
                }}
                className={`${!isEditable ? "hidden" : ""}`}
              />
              {!isEditable && (
                <input
                  type="text"
                  required
                  onChange={handleChange}
                  name="delegatePassword"
                  id="delegatePassword"
                  value={formData.delegatePassword}
                />
              )}
              {isEditable && sendPassword && (
                <>
                  <input
                    type="text"
                    required
                    onChange={handleChange}
                    name="delegatePassword"
                    id="delegatePassword"
                    value={formData.delegatePassword}
                  />
                </>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Address 1:</label>
              <input
                type="text"
                required
                onChange={handleChange}
                name="address1"
                id="address1"
                value={formData.address1}
              />
            </div>
            <div className="form-col">
              <label>Address 2:</label>
              <input
                type="text"
                onChange={handleChange}
                name="address2"
                id="address2"
                value={formData.address2}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label htmlFor="countryId">Country :</label>
              <select
                onChange={handleCountryChange}
                name="countryId"
                id="countryId"
                value={countryName}
              >
                <option value="">--select--</option>
                {countries.map((item, index) => (
                  <option key={index} value={item.locationName}>
                    {item.locationName}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-col">
              <label>State :</label>
              <select
                name="state"
                id="state"
                className="form-control"
                value={stateName}
                onChange={handleStateChange}
              >
                <option value="">--select--</option>
                {states.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.locationName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>City :</label>
              <select
                name="cityId"
                id="cityId"
                value={cityName}
                className="form-control"
                onChange={handleCityChange}
              >
                <option value="">--select--</option>
                {cities.map((item, index) => (
                  <option key={index} value={item.locationName}>
                    {item.locationName}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-col">
              <label>Pin Code:</label>
              <input
                type="text"
                onChange={handleChange}
                name="pinCode"
                id="pinCode"
                value={formData.pinCode}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Mobile No:</label>
              <input
                type="tel"
                onChange={handleChange}
                name="mobileNumber"
                id="mobileNumber"
                value={formData.mobileNumber}
              />
            </div>
            <div className="form-col">
              <label>Profile Pic:</label>

              <input
                type="file"
                onChange={handleChange}
                name="image"
                id="image"
                value={formData.image}
              />
              {selectedfileForEdit && (
                <div className="h-16 w-16">
                  <img
                    src={selectedfileForEdit}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="form-col">
              <label>Designation:</label>
              <input
                type="text"
                onChange={handleChange}
                name="designation"
                id="designation"
                value={formData.designation}
              />
            </div>
            <div className="form-col">
              <label>About My self :</label>
              <textarea
                rows="4"
                cols="50"
                onChange={handleChange}
                name="aboutMySelf"
                id="aboutMySelf"
                value={formData.aboutMySelf}
              ></textarea>
            </div>
          </div>
          <div className="form-row">
            <div className="form-col checkbox-style">
              <label htmlFor="checkbox">Show Email:</label>
              <input
                type="checkbox"
                onChange={handleCheckbox}
                name="showEmail"
                id="showEmail"
                checked={showEmail}
                style={{ height: "14px" }}
              />
            </div>
            <div className="form-col checkbox-style">
              <label htmlFor="checkbox ">Show Contact No :</label>
              <input
                type="checkbox"
                onChange={handleCheckbox}
                name="showContactNo"
                id="showContactNo"
                checked={showContactNo}
                style={{ height: "14px" }}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col checkbox-style">
              <label htmlFor="checkbox">Open For Appointment :</label>
              <input
                type="checkbox"
                onChange={handleCheckbox}
                name="openForAppointment"
                id="openForAppointment"
                checked={openForAppointment}
                style={{ height: "14px" }}
              />
            </div>
            <div className="form-col checkbox-style">
              <label htmlFor="checkbox">Is Published :</label>
              <input
                disabled={userType === "SUBADMIN"}
                type="checkbox"
                onChange={handleCheckbox}
                name="isPublished"
                id="isPublished"
                checked={isPublished}
                style={{ height: "14px" }}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col checkbox-style">
              <label htmlFor="checkbox">Send Mail :</label>
              <input
                type="checkbox"
                disabled={userType === "SUBADMIN"}
                onChange={handleCheckbox}
                name="sendMail"
                id="sendMail"
                checked={sendMail}
                style={{ height: "14px" }}
              />
            </div>
            <div className="form-col checkbox-style">
              <label htmlFor="checkbox">Payment :</label>
              <input
                type="checkbox"
                onChange={handleCheckbox}
                name="payment"
                id="payment"
                checked={payment}
                style={{ height: "14px" }}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Receipt No :</label>
              <input
                type="text"
                onChange={handleChange}
                name="receiptNo"
                id="receiptNo"
                value={formData.receiptNo}
              />
            </div>
            <div className="form-col">
              <label>Sponsorer :</label>
              <input
                type="text"
                onChange={handleChange}
                name="sponsorer"
                id="sponsorer"
                value={formData.sponsorer}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Currency :</label>
              <input
                type="text"
                onChange={handleChange}
                name="currency"
                id="currency"
                value={formData.currency}
                disabled
              />
            </div>
            <div className="form-col">
              <label>Seminar Fee :</label>
              <input
                type="text"
                onChange={handleChange}
                name="seminarFee"
                id="seminarFee"
                value={formData.seminarFee}
                disabled
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Remarks :</label>
              <input
                type="text"
                onChange={handleChange}
                name="remarks"
                id="remarks"
                value={formData.remarks}
              />
            </div>
            <div className="form-col">
              <label>Registration No :</label>
              <input
                type="text"
                onChange={handleChange}
                name="registrationNo"
                id="registrationNo"
                value={formData.registrationNo}
                disabled
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Show In Order :</label>
              <input
                type="number"
                onChange={handleChange}
                name="showInOrder"
                id="showInOrder"
                value={formData.showInOrder}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-col">
              <button className="btn">{isEditable ? "Save" : "Submit"}</button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddNewDelegate;
