import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { getNames } from "country-list";
import { getEvents } from "../../../../api/event";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  createExhibitor,
  getExhibitorById,
  updateExhibitor,
} from "../../../../api/exhibitor";
import {
  getAllCitiesByCountryId,
  getAllCountries,
} from "../../../../api/locationMaster";
import { useParams } from "react-router";

const AddNewExhibitor = ({ isEditable }) => {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    eventId: "",
    exhibitorName: "",
    exhibitorShortname: "",
    exhibitorDescription: "",
    exhibitorAddress: "",
    exhibitorCountryId: "",
    exhibitorCityId: "",
    pinCode: "",
    email: "",
    stallNo: "",
    exhibitorWebUrl: "",
    contactPerson: "",
    contactPersonNo: "",
    showInOrder: "",
  });
  const [isPublished, setIsPublished] = useState(false);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedfileForEdit, setSelectedFileforEdit] = useState(null);
  const params = useParams();

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const handleCheckBox = () => {
    setIsPublished(!isPublished);
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    if (name === "image") {
      setSelectedFileforEdit(null);
      setSelectedFile(e.target.files[0]);
    }
    setFormData({ ...formData, [name]: value });
  };
  const submit = async (e) => {
    e.preventDefault();
    if (!isEditable) {
      try {
        const events = await getEvents();
        const newFormData = new FormData();
        for (const key in formData) {
          newFormData.append(key, formData[key]);
        }
        newFormData.delete("eventId");
        newFormData.delete("image");
        newFormData.delete("isPublished");
        newFormData.append("eventId", events.data[0]._id);
        newFormData.append("image", selectedFile);
        newFormData.append("isPublished", isPublished);
        console.log(newFormData.get("image"));
        console.log(newFormData.get("isPublished"));
        const data = await createExhibitor(newFormData);
        toast.success(data.message);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    } else {
      try {
        const events = await getEvents();
        const newFormData = new FormData();
        for (const key in formData) {
          newFormData.append(key, formData[key]);
        }
        newFormData.delete("eventId");
        newFormData.delete("image");
        newFormData.delete("isPublished");
        newFormData.append("eventId", events.data[0]._id);
        newFormData.append("image", selectedFile);
        newFormData.append("isPublished", isPublished);
        const data = await updateExhibitor(newFormData, params.id);
        toast.success(data.message);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
  };

  const handleCountryChange = (e) => {
    setFormData({
      ...formData,
      exhibitorCountryId: e.target.value,
    });
    fetchAllCities(e.target.value);
  };

  const fetchAllCities = async (id) => {
    try {
      const data = await getAllCitiesByCountryId(id);
      setCities(data.data.data);
    } catch (error) {
      setCities([]);
      if (error.message === "Please ReLogin") {
        toast.error(error.message);
        localStorage.removeItem("userType");
        localStorage.removeItem("token");
        dispatch({ type: "logout" });
      }
      toast.error(error.message);
    }
  };
  const handleCityChange = (e) => {
    setFormData({ ...formData, exhibitorCityId: e.target.value });
  };

  useEffect(() => {
    fetchAllCountries();
    async function fetchAllCountries() {
      try {
        const data = await getAllCountries();
        setCountries(data.data);
      } catch (error) {
        if (error.message === "Please ReLogin") {
          toast.error(error.message);
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          dispatch({ type: "logout" });
        }
        toast.error(error.message);
      }
    }
  }, []);
  console.log(formData);

  useEffect(() => {
    if (isEditable) {
      const id = params.id;
      fetchExhibitorById(id);
      async function fetchExhibitorById(id) {
        try {
          const data = await getExhibitorById(id);

          setSelectedFileforEdit(data.data?.exhibitorLogo);
          setSelectedFile(null);
          setIsPublished(data.data?.isPublished);
          setFormData({
            eventId: data.data?.eventId,
            exhibitorName: data.data?.exhibitorName,
            exhibitorShortname: data.data?.exhibitorShortname,
            exhibitorDescription: data.data?.exhibitorDescription,
            exhibitorAddress: data.data?.exhibitorAddress,
            exhibitorCountryId: data.data?.exhibitorCountryId,
            exhibitorCityId: data.data?.exhibitorCityId,
            pinCode: data.data?.pinCode,
            email: data.data?.email,
            stallNo: data.data?.stallNo,
            contactPerson: data.data?.contactPerson,
            contactPersonNo: data.data?.contactPersonNo,
            showInOrder: data.data?.showInOrder,
            exhibitorWebUrl: data.data?.exhibitorWebUrl,
          });
          fetchAllCities(data.data?.exhibitorCountryId);
        } catch (error) {
          if (error.message === "Please ReLogin") {
            toast.error(error.message);
            localStorage.removeItem("userType");
            localStorage.removeItem("token");
            dispatch({ type: "logout" });
          }
          toast.error(error.message);
        }
      }
    } else {
      setSelectedFileforEdit(null);
      setSelectedFile(null);
      setIsPublished(false);
      setFormData({
        eventId: "",
        exhibitorName: "",
        exhibitorShortname: "",
        exhibitorDescription: "",
        exhibitorAddress: "",
        exhibitorCountryId: "",
        exhibitorCityId: "",
        pinCode: "",
        email: "",
        stallNo: "",
        contactPerson: "",
        contactPersonNo: "",
        showInOrder: "",
        exhibitorWebUrl: "",
      });
    }
  }, [params.id]);
  return (
    <div className={`yourComponent ${isExpanded ? "expanded" : ""}`}>
      <div className="horizontalLine"></div>
      <div className="header">
        <div className="title">Exhibitor Details</div>
        <button className="expandButton" onClick={toggleExpand}>
          {isExpanded ? "-" : "+"}
        </button>
      </div>
      {isExpanded && (
        <form className="form-container" onSubmit={submit}>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Exhibitor Name :</label>
              <input
                type="text"
                required
                name="exhibitorName"
                id="exhibitorName"
                onChange={handleOnChange}
                value={formData.exhibitorName}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Exhibitor Short Name :</label>
              <input
                type="text"
                name="exhibitorShortname"
                id="exhibitorShortname"
                onChange={handleOnChange}
                value={formData.exhibitorShortname}
              />
            </div>

            <div className="form-col">
              <label>Exhibitor Description :</label>
              <input
                type="text"
                name="exhibitorDescription"
                id="exhibitorDescription"
                onChange={handleOnChange}
                value={formData.exhibitorDescription}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-col">
              <label>Exhibitor Address :</label>
              <textarea
                rows="4"
                cols="50"
                name="exhibitorAddress"
                id="exhibitorAddress"
                onChange={handleOnChange}
                value={formData.exhibitorAddress}
              ></textarea>
            </div>

            <div className="form-col">
              <label className="mandatory-label">Country :</label>
              <select
                required
                name="sponserCountryId"
                id="sponserCountryId"
                multiple={false}
                value={formData.exhibitorCountryId}
                onChange={handleCountryChange}
              >
                <option value="">--select--</option>
                {countries.map((country, index) => (
                  <option key={index} value={country._id}>
                    {country.locationName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label className="mandatory-label">Exhibitor City :</label>
              <select
                name="sponserCityId"
                id="sponserCityId"
                multiple={false}
                value={formData.exhibitorCityId}
                onChange={handleCityChange}
                required
              >
                <option value="">--select--</option>
                {cities?.length > 0 &&
                  cities.map((city, index) => (
                    <option key={index} value={city._id}>
                      {city.locationName}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-col">
              <label>Pin Code :</label>
              <input
                type="text"
                name="pinCode"
                id="pinCode"
                onChange={handleOnChange}
                value={formData.pinCode}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Email Id :</label>
              <input
                type="text"
                name="email"
                id="email"
                onChange={handleOnChange}
                value={formData.email}
              />
            </div>

            <div className="form-col">
              <label>Exhibitor Logo :</label>
              <input
                type="file"
                onChange={handleOnChange}
                name="image"
                id="image"
                // value={formData.image}
              />
              {selectedfileForEdit && (
                <div className="h-16 w-16">
                  <img
                    src={selectedfileForEdit}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Stall No :</label>
              <input
                type="text"
                name="stallNo"
                id="stallNo"
                onChange={handleOnChange}
                value={formData.stallNo}
              />
            </div>

            <div className="form-col">
              <label>Exhibitor web Url :</label>
              <input
                type="text"
                name="exhibitorWebUrl"
                id="exhibitorWebUrl"
                onChange={handleOnChange}
                value={formData.exhibitorWebUrl}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <label>Contact Person :</label>
              <input
                type="text"
                name="contactPerson"
                id="contactPerson"
                onChange={handleOnChange}
                value={formData.contactPerson}
              />
            </div>

            <div className="form-col">
              <label>Contact Person No :</label>
              <input
                type="text"
                name="contactPersonNo"
                id="contactPersonNo"
                onChange={handleOnChange}
                value={formData.contactPersonNo}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col checkbox-style">
              <label htmlFor="checkbox">Is Published:</label>
              <input
                type="checkbox"
                name="isPublished"
                id="isPublished"
                onChange={handleCheckBox}
                checked={isPublished}
                style={{ height: "14px" }}
              />
            </div>
            <div className="form-col">
              <label>Show In Order :</label>
              <input
                type="text"
                required
                name="showInOrder"
                id="showInOrder"
                onChange={handleOnChange}
                value={formData.showInOrder}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <button className="btn">{isEditable ? "Save" : "Submit"}</button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddNewExhibitor;
