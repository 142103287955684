import axios from "axios";

// const token = localStorage.getItem("token");

const baseURL = process.env.REACT_APP_AWS_URL;
export const createCategory = async (formData) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(
      `${baseURL}/api/v1/company-categories`,
      formData
    );
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const getCompanyCategory = async () => {
  // console.log(token);
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(`${baseURL}/api/v1/company-categories`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const getCompanyCategoryById = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(
      `${baseURL}/api/v1/company-categories/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};

export const updateCategory = async (formData, id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.put(
      `${baseURL}/api/v1/company-categories/update/${id}`,
      formData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error("Please ReLogin");
    }
    throw new Error(error.response.data.message);
  }
};
